<template>
  <div>
    <div
      class="min-w-screen min-h-screen bg-blue-100 flex items-center p-5 lg:p-20 overflow-hidden relative"
    >
      <div
        class="flex-1 min-h-full min-w-full rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left"
      >
        <div class="w-full ">
          <div class="mb-10 lg:mb-20"></div>
          <div class="mb-10 md:mb-20 text-gray-600 font-light">
            <h1
              class="font-black uppercase text-3xl lg:text-5xl text-primary mb-10"
            >
              Restricted space.
            </h1>
            <p>The section you're viewing isn't available for you.</p>
            <!-- <p>Try searching again or use the Go Back button below.</p> -->
          </div>
        </div>
        <img class="h-56 object-cover" src="/img/access_denied.svg" />
      </div>
      <div
        class="w-64 md:w-96 h-96 md:h-full bg-blue-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform"
      ></div>
      <div
        class="w-96 h-full bg-yellow-200 bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform"
      ></div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
