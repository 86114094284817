<template>
  <div>
    <div v-if="canViewAudits">
      <div v-if="role.toLowerCase() == 'director' && unit.toLowerCase() === 'chairman'" class="p-4">
        <authorizer></authorizer>
      </div>
      <section v-else class="text-gray-600 body-font overflow-hidden ">
        <div class="w-full mb-6 lg:mb-0">
          <div class="flex mb-4 text-gray-500">
            <button @click="switchTabTo('new')" :class="
              `btnTab ${
                tab == 'new' ? 'border-blue-primary-dark' : 'border-gray-300'
              } `
            ">
              New
            </button>
            <button @click="switchTabTo('request')" :class="
              `btnTab ${
                tab == 'request'
                  ? 'border-blue-primary-dark'
                  : 'border-gray-300'
              } `
            ">
              Request
            </button>
            <button @click="switchTabTo('print')" :class="
              `btnTab ${
                tab == 'print'
                  ? 'border-blue-primary-dark'
                  : 'border-gray-300'
              } `
            ">
              Print
            </button>
          </div>
        </div>
        <transition name="slide-fade">
          <component :is="tabView" class="p-4" />
        </transition>
      </section>
    </div>
    <content-holder v-else :title="'Audits'" class=" p-4">
      <unauthorized-access></unauthorized-access>
    </content-holder>
  </div>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";
import NewRegistration from "@/components/audits/NewRegistration.vue";
import AuditPrint from "@/components/audits/AuditPrint.vue";
import Requests from "@/components/audits/Requests.vue";
import Authorizer from "../components/audits/Authorizer.vue";
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import ContentHolder from "../components/ContentHolder.vue";
import { views, role } from "@/helpers/login_details.js";
export default {
  components: {
    AuditPrint,
    Requests,
    Authorizer,
    UnauthorizedAccess,
    NewRegistration,
    ContentHolder,
  },
  data: () => ({
    canViewAudits: false,
  }),
  setup() {
    const tab = ref("new");
    const tabView = ref("NewRegistration");
    const user = ref("chiche");
    const tabViewOptions = reactive({
      new: "NewRegistration",
      print: "AuditPrint",
      request: "Requests",
    });
    const $store = useStore();
    const unit = computed(() => {
      return $store.getters.getUnit;
    });

    function switchTabTo(tabName) {
      tab.value = tabName;
      tabView.value = tabViewOptions[tabName];
    }

    return { user, tab, tabView, switchTabTo, unit, role };
  },
  created() {

    if (views.includes("Audits")) {
      this.canViewAudits = true;
    } else {
      this.canViewAudits = false;
    }
  },
};
</script>

<style>

</style>
