import { mapGetters, mapActions } from "vuex";
// import { mixin as clickaway } from "vue-clickaway";

export const auditMixin = {
  components: {},
  // mixins: [clickaway],
  data: () => ({
    alert: {
      state: false,
      mode: "error",
      msg: "It seems an error occurred, please try again...",
    },
    error: {
      state: false,
      msg: "It seems an error occurred, please try again...",
    },
  }),
  computed: {
    ...mapGetters([
      "getCompaniesCount",
      "getAuditCompanies",
      "getAuditRequests",
    ]),
    currentYear() {
      return new Date().getFullYear().toString();
    },
  },
  methods: {
    ...mapActions([
      "createNewCompany",
      "getCompanyList",
      "updateCompany",
      "deleteCompany",
      "sendAuditRequests",
      "authorizeAllAudits",
      "alterAuditCompanies",
      "authorizeSingleAudit",
      "getAllAuditRequests",
      "getCompaniesListForAudit",
      "sendAuditRequests",
    ]),
    showError(msg) {
      if (!msg.includes("Internal Server Error occured in DB operation")) {
        clearTimeout(this.timeout);
        this.alert.state = true;
        this.alert.msg = msg;
        this.alert.mode = "error";
        this.timeout = setTimeout(() => {
          this.alert.state = false;
        }, 10000);
      }
    },
    showSuccess(msg) {
      clearTimeout(this.timeout);
      this.alert.state = true;
      this.alert.msg = msg;
      this.alert.mode = "success";
      this.timeout = setTimeout(() => {
        this.alert.state = false;
      }, 10000);
    },
  },
};
