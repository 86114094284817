<template>
  <div>
    <audit-print-preview v-if="print" @cancelPrint="print = false" :allAuditDetails="allAuditDetails">
    </audit-print-preview>
    <content-holder :title="'Audits'" class="main-h-2 overflow-y-scroll hide-scrollbar">
      <alert-banner v-if="alert.state" :alert="alert" @close="alert.state = false"></alert-banner>

      <div v-if="!loadingData && auditRequests.length < 1 && companyName == ''">
        <p
          class="flex items-center transition text-center text-white bg-blue-primary font-medium text-md md:text-lg w-full mb-2 px-4 py-4 rounded-md">
          <span class="w-full">No audit requests at this moment...</span>
        </p>
      </div>

      <h2 class="text-gray-700 text-lg mb-4 font-medium title-font">Request details</h2>

      <input type="text" id="name" name="name" placeholder="Enter company name to filter" autocomplete="off"
        v-model="companyName"
        class="w-full bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />

      <div v-if="loadingData" class="w-full mt-32 flex items-center text-center">
        <i
          :class="`lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"></i>
      </div>

      <div v-else class="h-5/6">
        <button @click="printAllAudits" id="btnPrintAll" type="button"
          class="mt-4 font-semibold text-gray-600 text-md focus:outline-none">
          print all
        </button>
        <div class="overflow-x-auto h-5/6 overflow-y-scroll mx-0 sm:mx-0 mt-8 mb-4 sm:mt-2">
          <div class="py-2 mt-2 align-middle inline-block min-w-full">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                      <span class="sr-only">Edit</span>
                      Actions
                    </th>
                    <th scope="col"
                      class="px-6 py-3  text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <template class="flex flex-row items-center">
                        Customer Name
                        <div v-if="auditRequests.length > 0" class="ml-2 cursor-pointer">
                          <i v-if="sortByName === 'asc'" @click="sortReceiptsByName('desc')"
                            class="fas fa-arrow-down text-xs"></i>
                          <i v-else @click="sortReceiptsByName('asc')" class="fas fa-arrow-up text-xs"></i>
                        </div>
                      </template>
                    </th>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Address
                    </th>
                    <th scope="col"
                      class="px-6 py-3  text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <template class="flex flex-row items-center">
                        Year Range
                        <div v-if="auditRequests.length > 0" class="ml-2 cursor-pointer">
                          <i v-if="sortByYearRange === 'asc'" @click="sortReceiptsByYearRange('desc')"
                            class="fas fa-arrow-down text-xs"></i>
                          <i v-else @click="sortReceiptsByYearRange('asc')" class="fas fa-arrow-up text-xs"></i>
                        </div>
                      </template>

                    </th>
                    <th scope="col"
                      class="px-6 py-3 flex flex-row items-center text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                      <div v-if="auditRequests.length > 0" class="ml-2 cursor-pointer">
                        <i v-if="sortByStatus === 'asc'" @click="sortReceiptsByStatus('desc')"
                          class="fas fa-arrow-down text-xs"></i>
                        <i v-else @click="sortReceiptsByStatus('asc')" class="fas fa-arrow-up text-xs"></i>
                      </div>
                    </th>

                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="req in auditRequests" :key="req.audit_ref">
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div v-if="true" class="flex items-center space-x-6 font-medium">
                        <button type="button" @click="printAudit(req)" :disabled="getStatus(req)"
                          class="focus:outline-none text-blue-primary hover:text-blue-primary-dark">
                          Print
                        </button>
                        <!-- <button
            v-if="true"
            class="focus:outline-none text-red-600 hover:text-red-900"
           >
            Delete
           </button> -->
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm font-medium text-gray-900">
                        {{ req.company_name }}
                      </div>
                    </td>
                    <td class="px-6 py-4 break-normal">
                      <div class="text-sm text-gray-900 w-80">
                        {{ req.company_address }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ req.year_range }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div :class="`${req.status.toLowerCase() === 'pending' ? 'bg-yellow-500' : 'bg-green-500'
                      } rounded-full px-2 py-1 text-white text-center font-medium`">
                        {{ req.status }}
                      </div>
                    </td>
                  </tr>

                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </content-holder>
  </div>
</template>

<script>
import ContentHolder from "@/components/ContentHolder.vue";
import AuditPrintPreview from "./AuditPrintPreview.vue";
import { auditMixin } from "@/mixins/audit.js";
import AlertBanner from "@/components/AlertBanner.vue";
import Swal from "sweetalert2";
export default {
  components: { ContentHolder, AuditPrintPreview, AlertBanner },
  mixins: [auditMixin],
  data: () => ({
    newLoading: false,
    loadingData: false,
    print: false,
    auditDetails: "",
    sortByName: 'asc',
    sortByYearRange: 'asc',
    sortByStatus: 'asc',
    allAuditDetails: [],
    requests: [],
    companyName: "",
    auditPrintInterval: null,
  }),
  watch: {
    sortByYearRange(val) {
      let name1, name2;
      this.requests = this.requests.sort((a, b) => {

        if (val !== 'asc') {
          name1 = a.year_range.toUpperCase();
          name2 = b.year_range.toUpperCase();
        } else {
          name1 = b.year_range.toUpperCase();
          name2 = a.year_range.toUpperCase();
        }

        let comparison = 0;

        if (name1 > name2) {
          comparison = 1;
        } else if (name1 < name2) {
          comparison = -1;
        }
        return comparison;
      });
    },
    sortByName(val) {
      let name1, name2;
      this.requests = this.requests.sort((a, b) => {

        if (val !== 'asc') {
          name1 = a.company_name.toUpperCase();
          name2 = b.company_name.toUpperCase();
        } else {
          name1 = b.company_name.toUpperCase();
          name2 = a.company_name.toUpperCase();
        }

        let comparison = 0;

        if (name1 > name2) {
          comparison = 1;
        } else if (name1 < name2) {
          comparison = -1;
        }
        return comparison;
      });
    },
    sortByStatus(val) {
      let name1, name2;
      this.requests = this.requests.sort((a, b) => {

        if (val !== 'asc') {
          name1 = a.status.toUpperCase();
          name2 = b.status.toUpperCase();
        } else {
          name1 = b.status.toUpperCase();
          name2 = a.status.toUpperCase();
        }

        let comparison = 0;

        if (name1 > name2) {
          comparison = 1;
        } else if (name1 < name2) {
          comparison = -1;
        }
        return comparison;
      });
    }
  },
  computed: {
    auditRequests() {
      return this.requests.filter((comp) => {
        return comp.company_name.toLowerCase().includes(this.companyName.toLowerCase());
      });
    },
  },
  methods: {
    sortReceiptsByName(direction) {
      this.sortByName = direction;
    },
    sortReceiptsByStatus(direction) {
      this.sortByStatus = direction;
    },
    sortReceiptsByYearRange(direction) {
      this.sortByYearRange = direction;
    },
    getStatus(req) {
      return req.status === "pending" ? true : false;
    },
    printAudit(details) {
      //    console.log(details);
      this.stopFetchingAuditRequests();
      this.allAuditDetails = [details];
      this.print = true;
    },
    printAllAudits() {
      //    console.log(details);
      this.stopFetchingAuditRequests();
      let ar = [];
      this.requests.forEach((r) => {
        if (r.status == "Authorized") ar.push(r);
      });
      this.allAuditDetails = ar;

      if (ar.length > 0) this.print = true;
    },
    stopFetchingAuditRequests() {
      clearInterval(this.auditPrintInterval);
    },
    startFetchingAuditRequests() {
      //  this.auditPrintInterval = setInterval(() => {
      this.getAllAuditRequests({ status: ["pending", "Authorized"] })
        .then(() => {
          this.loadingData = false;
          this.requests = this.getAuditRequests;
        })
        .catch((err) => {
          if (!err.includes("Network Error") && !err.toLowerCase().includes("timeout")) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err,
            });
          }
          this.loadingData = false;
        });
      //  }, 10000);
    },
  },
  mounted() {
    this.loadingData = true;
    this.startFetchingAuditRequests();
  },
  beforeUnmounted() {
    this.stopFetchingAuditRequests();
  },
};
</script>

<style>

</style>
