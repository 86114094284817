<template>
  <content-holder :title="''" class="main-h-2 pt-0 overflow-y-scroll">

    <div class="w-full sm:bg-gray-50 px-2 sm:px-0">
      <div class=" flex flex-col md:mx-auto sm:w-9/12 md:py-8 mt-0 md:mt-0 sm:px-4">
        <alert-banner v-if="alert.state" :alert="alert" @close="alert.state = false"></alert-banner>

        <div class="w-full">

          <div class="flex flex-wrap -mx-3 overflow-hidden pb-12">
            <p class="text-gray-600 font-semibold w-full mx-3 mb-4 pb-2">
              Company details
            </p>
            <div class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">

              <label for="name" class="leading-7 text-sm text-gray-600">Company TIN</label>
              <input type="text" id="name" name="name" autocomplete="off" v-model="payer.tin"
                class="w-full bg-white rounded border-2 border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
            </div>
            <div class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label for="name" class="leading-7 text-sm text-gray-600">Company name</label>
              <input type="text" id="name" name="name" autocomplete="off" v-model="payer.company_name"
                class="w-full bg-white rounded border-2 border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
            </div>
            <div class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label for="name" class="leading-7 text-sm text-gray-600">Receiver's title</label>

              <input type="text" id="name" name="name" autocomplete="off" v-model="payer.receiver"
                class="w-full bg-white rounded border-2 border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
            </div>
            <div class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label for="name" class="leading-7 text-sm text-gray-600">Year Range</label>
              <input type="phone" v-model="payer.year_range" autocomplete="off"
                class="w-full bg-white rounded border-2 border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
            </div>
            <div class="my-3 px-3 w-full overflow-hidden ">
              <label for="address" class="leading-7 text-sm text-gray-600">Address</label>
              <textarea id="address" name="address" v-model="payer.company_address"
                class="w-full bg-white rounded border-2 border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary h-20 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
            </div>
          </div>

          <div class="flex items-center justify-start space-x-4 w-full">
            <button @click="addPayer" id="btnAddAudit" type="button"
              class="w-full px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-md bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none">
              Add new
            </button>
            <button @click="updatePayer" id="btnEditAudit" type="button" :disabled="editLoading"
              class="hidden px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-md bg-blue-primary hover:bg-blue-primary-dark focus:ring focus:ring-blue-700 focus:outline-none">
              <div v-if="!editLoading">Update</div>
              <div v-else class="flex items-center space-x-4 justify-center">
                <i class="fas fa-circle-notch animate-spin"></i>
                <span>Please wait...</span>
              </div>
            </button>
          </div>
        </div>
        <!-- <p class="text-xs text-gray-500 mt-3">
     Chicharrones blog helvetica normcore iceland tousled brook viral artisan.
    </p> -->
      </div>
    </div>

    <div class="hidden md:mx-auto sm:w-9/12">
      <input type="text" name="taxpayer" v-model="taxpayer" placeholder="Enter tax payer's name to filter"
        class="w-full mt-10 sm:mt-0 h-12 px-4 mb-2 border-2 text-gray-700 font-medium border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-600" />
      <div v-if="auditRequests.length > 0" class=" mx-0 sm:mx-0 overflow-x-auto mt-6 sm:mt-0">
        <div class="h-80 overflow-auto py-2 align-middle inline-block min-w-full">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    <span class="sr-only">Edit</span>
                    Actions
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Company Name
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Receiver
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Address
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Year Range
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="payer in auditRequests" :key="payer.company_id">
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div class="flex items-center space-x-6 font-medium">
                      <button @click="editDetails(payer)"
                        class="focus:outline-none text-blue-primary hover:text-blue-primary-dark">
                        Edit
                      </button>
                      <button @click="deleteRecord(payer)" v-if="true"
                        class="focus:outline-none text-red-600 hover:text-red-900">
                        Delete
                      </button>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      {{ payer.company_name }}
                    </div>
                  </td>
                  <td class="px-6 py-4 break-normal">
                    <div class="text-sm text-gray-900">
                      {{ payer.receiver }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      {{ payer.company_address }}
                    </div>
                  </td>
                  <td class="px-6 py-4 break-normal">
                    <div class="text-sm text-gray-900">
                      {{ payer.year_range }}
                    </div>
                  </td>
                </tr>

                <!-- More items... -->
              </tbody>
            </table>
          </div>
        </div>
        <div class="hidden bg-white py-3  items-center justify-between border-t border-gray-200">
          <div class="flex-1 flex justify-between sm:hidden">
            <button @click="previousRecord"
              class="focus:outline-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
              Previous
            </button>
            <button @click="nextRecord"
              class="focus:outline-none ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
              Next
            </button>
          </div>
          <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Showing
                <span class="font-medium">{{ search.startNum + 1 }}</span>
                to
                <span class="font-medium">{{ search.tracker }}</span>
                of
                <span class="font-medium">{{ getCompaniesCount }}</span>
                results
              </p>
            </div>
            <div>
              <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button @click="previousRecord"
                  class="relative focus:outline-none inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <span class="sr-only">Previous</span>
                  <!-- Heroicon name: solid/chevron-left -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </button>

                <button @click="nextRecord"
                  class="relative focus:outline-none inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <span class="sr-only">Next</span>
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>

    </div>

  </content-holder>
</template>

<script>
import ContentHolder from "@/components/ContentHolder.vue";
import AlertBanner from "@/components/AlertBanner.vue";
import { auditMixin } from "@/mixins/audit";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  components: { ContentHolder, AlertBanner },
  mixins: [auditMixin],
  data: () => ({
    payer: {
      company_name: "",
      company_address: "",
      receiver: "",
      year_range: "",
      tin: ""
    },
    auditRequests: [],
    search: { limit: 10, startNum: 0, tracker: 10 },
    id: 0,
    payers: [],
    timeout: null,
    newLoading: false,
    loadingData: false,
    editLoading: false,
    print: false,
    auditDetails: "",
    taxpayer: "",
  }),
  computed: {
    ...mapGetters(["getCompanies"]),

    companies() {
      if (this.auditRequests)
        return this.auditRequests.filter((comp) => {
          return comp.name.toLowerCase().includes(this.taxpayer.toLowerCase());
        });
      else return [];
    },
  },
  methods: {
    nextRecord() {
      if (this.search.startNum + this.search.limit <= this.getCompaniesCount) {
        this.search.startNum = this.search.startNum + this.search.limit;
        this.search.tracker += this.search.startNum;

        if (this.search.tracker > this.getCompaniesCount)
          this.search.tracker = this.getCompaniesCount;

        this.getCompanyList(this.search);
      }
    },
    previousRecord() {
      if (this.search.startNum > 0) {
        this.search.tracker = this.search.startNum;
        this.search.startNum = this.search.startNum - this.search.limit;

        this.getCompanyList(this.search);
      }
    },
    editDetails(details) {
      this.payer.company_name = details.name;
      this.payer.company_address = details.address;
      this.id = details.company_id;
    },
    resetFields() {
      this.payer = {
        company_name: "",
        company_address: "",
        receiver: "",
        year_range: "",
        tin: ""
      };
      this.id = 0;
    },
    fetchCompanies() {
      this.getCompanyList(this.search)
        .then(() => {
          //  console.log(this.search, this.companies.length);

          if (this.search.tracker > this.getCompaniesCount)
            this.search.tracker = this.getCompaniesCount;

          if (this.search.tracker < this.companies.length)
            this.search.tracker = this.companies.length;
        })
        .catch((err) => {
          // Swal.fire({
          //   icon: "error",
          //   title: "Oops...",
          //   text: err
          // });
          this.showError(err);
        });
    },
    addAuditRequest() {
      //  console.log(this.id);
      if (this.payer.company_name == "" || this.payer.company_address == "" || this.payer.receiver == "" || this.payer.year_range == "") {
        this.showError(
          "Please don't click add when you've not provided all details!!!"
        );
        return;
      }

      if (this.id !== 0) {
        this.showError("You're trying to add a company that already exists!!!");
      } else {
        this.auditRequests.push(this.payer);
      }
    },
    addPayer() {
      //  console.log(this.id);
      if (this.payer.company_name == "" || this.payer.company_address == "" || this.payer.receiver == "" || this.payer.year_range == "") {
        this.showError(
          "Please don't click add when you've not provided all details!!!"
        );
        return;
      }

      if (this.id !== 0) {
        this.showError("You're trying to add a company that already exists!!!");
      } else {
        this.newLoading = true;
        this.sendAuditRequests({ companies: [this.payer] })
          .then((res) => {
            this.resetFields();
            // this.showSuccess("Request created!!!");
            Swal.fire({
              icon: "success",
              title: "Revenue House",
              text: res,
            });
            // this.newLoading = false;
            // this.fetchCompanies();
          })
          .catch((err) => {
            console.log(err);
            this.showError(err);
            this.newLoading = false;
          });
      }
    },
    updatePayer() {
      if (this.payer.company_name == "" || this.payer.company_address == "") {
        this.showError(
          "Please don't click update when you've not provided all details!!!"
        );
        return;
      }

      if (this.id === 0) {
        this.showError(
          "You're trying to update a company you've not selected!!!"
        );
      } else {
        this.editLoading = true;
        this.updateCompany({ id: this.id, data: this.payer })
          .then(() => {
            this.resetFields();
            this.showSuccess("Record updated!!!");
            this.editLoading = false;
            this.fetchCompanies();
          })
          .catch((err) => {
            console.log(err);
            this.showError(err);
            this.editLoading = false;
          });
      }
    },
    deleteRecord(details) {
      Swal.fire({
        title:
          "Are you sure? <br> Because you can't undo what you're about to do",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        //  console.log(details);
        if (result.isConfirmed) {
          this.deleteCompany(details.company_id)
            .then(() => {
              this.fetchCompanies();
              Swal.fire(
                "Deleted!",
                "Record has been deleted, never to return.",
                "success"
              );
            })
            .catch((err) =>
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err,
              })
            );
        }
      });
    },
  },
  mounted() {
    // this.loadingData = true;
    // this.getCompanyList(this.search)
    //   .then(() => {
    //     if (this.search.tracker > this.getCompaniesCount)
    //       this.search.tracker = this.getCompaniesCount;

    //     this.loadingData = false;
    //     //  console.log(this.search);
    //   })
    //   .catch((err) => {
    //     // Swal.fire({
    //     //   icon: "error",
    //     //   title: "Oops...",
    //     //   text: err
    //     // });
    //     this.showError(err);
    //     this.loadingData = false;
    //   });
  },
};
</script>

<style>

</style>
