<template>
  <div class="fixed z-40 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div
          class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-between items-center"
        >
          <h2 class="sm:pl-4 mb-4 sm:mb-0 text-gray-600 font-semibold">
            Print Preview
          </h2>
          <div>
            <button
              @click="print()"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Print
            </button>
            <button
              @click="cancelPrint"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
              id="details"
              class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
            >
              <div id="details-holder" class="w-full">
                <div
                  v-for="auditDetails in allAuditDetails"
                  :key="auditDetails.id"
                  :class="`w-full my-6 ${
                    ' details-holder-' +
                    auditDetails.id +
                    ' border-b border-gray-400'
                  }`"
                >
                  <div :class="`id-${auditDetails.id}`" class="text-left">
                    {{ auditDetails.audit_ref }}
                  </div>
                  <div :class="`date-${auditDetails.id}`" class="text-right">
                    {{ printDate }}
                  </div>
                  <div
                    :class="`receiver-${auditDetails.id}`"
                    class="w-full sm:w-3/4 text-left mt-6"
                  >
                    <p
                      :class="`address-${auditDetails.id}`"
                      class="font-medium w-full sm:w-3/4"
                      style="width: 20vw"
                    >
                      <b>{{ auditDetails.receiver }},</b> <br />
                      {{ auditDetails.company_name }}, <br />
                      {{ auditDetails.company_address }}.
                    </p>
                  </div>
                  <div
                    :class="`salute-${auditDetails.id}`"
                    class="my-6 text-left"
                  >
                    Dear Sir,
                  </div>
                  <div
                    :class="`heading-${auditDetails.id}`"
                    class="text-center font-bold"
                  >
                    <b
                      >NOTIFICATION OF TAX AUDIT EXERCISE
                      {{ fixYearRange(auditDetails.year_range) }}</b
                    >
                  </div>

                  <div
                    id="letter-body"
                    :class="`paragraph-${auditDetails.id}-body`"
                  >
                    <div
                      :class="`first-paragraph-${auditDetails.id}`"
                      class="my-6 text-justify"
                    >
                      We hereby inform you that the Audit Staff of the
                      <b>Bayelsa State Board of Internal Revenue</b> are to
                      carry out back tax audit of your organization for the
                      period stated above.
                    </div>
                    <div
                      :class="`second-paragraph-${auditDetails.id}`"
                      class="text-justify"
                    >
                      The audit exercise will come up on the ……………………… to verify
                      your organization’s tax records in order to ascertain the
                      adequacy of your remittances of PAYE deductions from staff
                      salaries and Withholding tax on contracts and supplies in
                      pursuant to section 48(4) of the
                      <b>Personal Income Tax (Amendment) Act 2011.</b>
                    </div>
                    <div
                      :class="`third-paragraph-${auditDetails.id}`"
                      class="text-justify my-6"
                    >
                      Please, note that the attached list is for audit exercise
                      which you are expected to make available to the tax audit
                      team. It should be noted that submission of incorrect
                      documents or false information/report or any other action
                      that may be considered as an impediment to the success of
                      this tax audit exercise by your organization will attract
                      appropriate sanction and penalty in accordance with the
                      provisions of sections 94, 95 and 96 of the
                      <b>Personal Income Tax (Amendment) Act 2011.</b>
                    </div>
                    <div
                      :class="`fourth-paragraph-${auditDetails.id}`"
                      class="text-justify my-4"
                    >
                      We hope you will put in place all necessary arrangements
                      to ensure that the Tax Audit exercise is successfully
                      carried out and completed on or before ……………………….
                    </div>
                    <div
                      :class="`fifth-paragraph-${auditDetails.id}`"
                      class="text-justify my-4"
                    >
                      Alternatively you can send copies of the required
                      documents to
                      <b
                        >info@bir.by.gov.ng & ransome.omukoro@bir.by.gov.ng or
                        call 08037098762 or 08038834317</b
                      >
                      for further clarifications.
                    </div>
                  </div>
                  <div :class="`signature-${auditDetails.id}`" class="mt-20">
                    <img
                      :src="require('@/assets/chairman.png')"
                      alt="signature"
                      :class="`image-${auditDetails.id}`"
                      class="image w-52 object-cover"
                    />
                    <p class="font-bold">
                      <b>Prof. Nimibofa Ayawei</b><br /><i
                        >Executive Chairman</i
                      >
                    </p>
                    <!-- <p class="font-bold"><b>OVIE-IZIBE VICTOR</b></p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
     <button
      @click="print()"
      type="button"
      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
     >
      Print
     </button>
     <button
      @click="cancelPrint"
      type="button"
      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
     >
      Cancel
     </button>
    </div> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import printJS from "print-js";
import Swal from "sweetalert2";
import moment from "moment";
import { mapActions } from "vuex";
export default {
  props: {
    auditDetails: { type: Object },
    allAuditDetails: { type: Array },
  },
  data: () => ({
    pageHeight: 11,
  }),
  computed: {
    printDate() {
      return moment().format("Do MMM, YYYY");
    },
  },
  methods: {
    ...mapActions(["singleAuditPrintDone", "allAuditPrintDone"]),
    cancelPrint() {
      this.$emit("cancelPrint");
    },
    fixYearRange(range) {
      let rangeSplit = range.toString().split("-") || [];

      if (rangeSplit.length > 1) {
        if (rangeSplit[0].trim() === rangeSplit[1].trim()) {
          return rangeSplit[0];
        } else {
          return range;
        }
      } else {
        return range;
      }
    },
    async print() {
      let s = "";

      this.allAuditDetails.forEach((a) => {
        s += ` .details-holder-${a.id} {
         position: relative;
            width: auto !important;
            height: 11.5in !important;
            text-overflow: hidden;
            break-after: page !important;
            margin-left: 0.2cm !important;
            margin-right: 0.5cm !important;
           font-family: ${"Times New Roman,Times,serif"};
        } 
       
        
        .id-${a.id}{
           
           height: auto !important;
           top: 2cm !important;
           left: 2cm !important;
           position: absolute !important;
           
       }
       .date-${a.id}{
         font-size: 1em !important;
           width: 5cm !important;
           height: auto !important;
           text-align: right;
           font-weight: bold;
           top: 5.5cm;
           left: 12.5cm;
           margin-bottom: 3em;
           position: absolute;
       }
       .receiver-${a.id}{
           
           width: 20em !important;
           height: 5em !important;
           margin-bottom: 0in !important;
           position: absolute;
           top: 5cm;
           left: 0cm;
           font-size: 1.2em !important;
           
       }
       .receiver-${a.id} span{
          margin-bottom: -5in !important;
         }
       .address-${a.id}{
           font-size: .8em !important;
           width: 20em !important;
           height: 5em !important;
           margin-bottom: 2in !important;
           position: relative;
           
       }
       .address-${a.id} b{
         font-size: 1.3em !important;
        }
       .salute-${a.id}{
           font-size: 1.3em !important;
           margin-bottom: 1em;
           width: 20cm !important;
           height: auto !important;
           text-align: left;
           position: absolute;
           top: 9cm;
           left: 0cm;
       }
       
       
       .heading-${a.id}{
           width: 20cm !important;
           height: auto !important;
           text-align: center;
           margin-bottom: 2em;
           position: absolute;
           font-size: 1.3em !important;
           top: 10cm;
           left: 0cm;
           
       }
       .paragraph-${a.id}-body{
         position: absolute;
         font-size: 1.3em !important;
         letter-spacing: -0.05em !important;
         top: 11cm;
        left: 0cm;
        width: 20cm !important;
        }
        
       .first-paragraph-${a.id}{
           
           
          position: relative;
          width: 20cm !important;
           text-align: justify;
           
       }
       .second-paragraph-${a.id}{
           
           
          position: relative;
          width: 20cm !important;
           text-align: justify;
           margin-bottom: -20px !important;
           }
       .third-paragraph-${a.id}{
           
           
           position: absolute;
           width: 20cm !important;
           text-align: justify;
           top:4.8cm !important;
           
       }
        .fourth-paragraph-${a.id}{
           height: 1cm !important;
           
           position: absolute;
           width: 20cm !important;
           text-align: justify;
           top: 8.3cm !important;
           
       }
        .fifth-paragraph-${a.id}{
           height: 1cm !important;
           position: absolute;
           width: 20cm !important;
           text-align: justify;
           top: 10cm !important;
           font-size: 1em !important;
           
       }
       .signature-${a.id}{
          font-size: 1.3em !important;
         letter-spacing: 0em !important;
           height: auto !important;
           position: absolute;
           top: 23cm !important;
           left: 0cm;
           
       }

       
       
        
        `;
      });

      let style = `
   @media print{

       #details {
           position: relative;
           width: auto !important;
           height: auto;
           
       }

       #details-holder {
           position: relative;
            width: auto !important;
            height: auto !important;
        }

        ${s} 

        

       .image{
           
           height: 100px !important;
           width: 350px !important;
       }
   }
    
    `;

      await printJS({
        printable: "details-holder",
        type: "html",
        header: null,
        style: style,
        font_size: "",
        onError(err) {
          console.log(err);
        },
        onPrintDialogClose() {
          console.log("printing done...");
        },
      });
      await this.showPrintConfirmationDialog();
    },
    showPrintConfirmationDialog() {
      let timerInterval;
      Swal.fire({
        title: "Loading...",
        timer: 2000,
        timerProgressBar: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.fire.showLoading();
          timerInterval = setInterval(() => {
            const content = Swal.fire.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.fire.getTimerLeft();
              }
            }
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.fire.DismissReason.timer) {
          Swal.fire({
            title: "Are you done printing?",
            showDenyButton: true,
            confirmButtonText: `Yes`,
            allowOutsideClick: false,
            denyButtonText: `No`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            // console.log(details);
            if (result.isConfirmed) {
              //  Swal.fire("Deleted!", "", "success");
              //    console.log(result);
              if (this.allAuditDetails.length < 2)
                this.singleAuditPrintDone({ ids: [this.allAuditDetails[0].id] })
                  .then(() => {
                    this.$emit("cancelPrint");
                  })
                  .catch((err) => {
                    if (
                      !err.includes("Network Error") &&
                      !err.toLowerCase().includes("timeout")
                    ) {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: err,
                      });
                    }
                  });
              else if (this.allAuditDetails.length > 1) {
                let ids = [];
                this.allAuditDetails.forEach((ad) => {
                  ids.push(ad.id);
                });

                this.allAuditPrintDone({ ids })
                  .then(() => {
                    this.$emit("cancelPrint");
                  })
                  .catch((err) => {
                    if (
                      !err.includes("Network Error") &&
                      !err.toLowerCase().includes("timeout")
                    ) {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: err,
                      });
                    }
                  });
              }
            }
          });
        }
      });
    },
  },
  mounted() {
    window.document
      .getElementById("details-holder")
      .addEventListener("onbeforeprint", () => {
        console.log("This will be called before the user prints.");
      });
  },
};
</script>

<style>
/* @media print {
 #details {
  width: 1000px;
 }
 .details-holder {
  width: 1000px;
  background-color: red;
 }
} */
</style>
