<template>
  <transition
    name="appear-anim"
    enter-active-class="transition ease-linear duration-300"
    enter-from-class="transform opacity-0 scale-0"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-300"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0  scale-0"
  >
    <p
      v-if="alert.state"
      class="animate__animated animate__zoomIn animate__faster"
      :class="
        `flex items-center transition text-center ${
          alert.mode == 'error'
            ? 'text-red-500 bg-red-200 '
            : 'text-green-500 bg-green-200 '
        } font-medium text-md md:text-lg  mb-2  px-4 py-4 rounded-md`
      "
    >
      <span class="w-full">{{ alert.msg }}</span>
      <i
        @click="close"
        class="
     `transition
     w-12
     cursor-pointer
     rounded-full
     hover:scale-105
     font-semibold
     fas
     fa-times-circle
     px-2
     py-2`
    "
      ></i>
    </p>
  </transition>
</template>

<script>
export default {
  props: ["alert"],
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style></style>
